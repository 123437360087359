import "../../styles/layouts/logistics-services.scss";

import { graphql, PageProps } from "gatsby";
import React from "react";

import { wavePAQs_svg } from "../../assets/svg/home_elements";
import BenefitsAndInfo, { Benefits, Info } from "../../components/BenefitsAndInfo/BenefitsAndInfo";
import BrandsLogistics from "../../components/Brands/BrandsLogistics";
import Gallery from "../../components/Gallery/Gallery";
import HeroService from "../../components/Hero/HeroService/HeroService";
import Layout from "../../components/Layout/layout";
import { SEO } from "../../components/SEO/SEO";
import ServiceMarkupSchema from "../../components/SEO/ServiceMarkupSchema";
import ServicesResume, { ServiceResume } from "../../components/ServicesResumen/ServicesResume";
import SubscribeNewsletter from "../../components/SubscribeNewsletter/SubscribeNewsletter";
import Video from "../../components/Video/Video";
import { Problem } from "../../components/WeKnowYourProblems/ProblemCard/ProblemCard";
import WeKnowYourProblems from "../../components/WeKnowYourProblems/WeKnowYourProblems";
import WeTalk from "../../components/WeTalk/WeTalk";
import { Photo, Slug } from "../../types/shared";

const LogisticsServicesTemplate = ({ data }: PageProps<Queries.LogisticsServicesQuery>) => {
  if (!data.sanityTemplateLogisticsServices) return;
  const photosList = data.sanityTemplateLogisticsServices.gallery as Photo[];

  const has_video = !!data.sanityTemplateLogisticsServices.video?.code;
  let videoThumbnail: Photo | null = null;
  if (has_video) {
    const alt = data.sanityTemplateLogisticsServices.video?.alt ? data.sanityTemplateLogisticsServices.video?.alt : "";
    videoThumbnail = {
      image: data.sanityTemplateLogisticsServices.video?.photo,
      alt,
    };
  }

  const servicesResumenList: ServiceResume[] = [];
  data.allSanityTemplateLogisticsServices.nodes.forEach((service) => {
    if (!service.hero?.title || !service.seo?.thumbnail) {
      return;
    }
    servicesResumenList.push({
      name: service.hero.title,
      photo: { image: service.seo.thumbnail, alt: `Foto de portada de ${service.hero.title}` } as unknown as Photo,
      slug: { current: `/logistica/${service.slug?.current}/` } as Slug,
    });
  });

  return (
    <Layout>
      <div className="l-logistics-services">
        <HeroService
          title={data.sanityTemplateLogisticsServices.hero?.title}
          subtitle={data.sanityTemplateLogisticsServices.hero?.subtitle}
          resume={data.sanityTemplateLogisticsServices.hero?._rawText}
          photo={data.sanityTemplateLogisticsServices.hero?.photo as unknown as Photo}
        />
        {data.sanityTemplateLogisticsServices.has_brands && <BrandsLogistics />}
        <Gallery photosList={photosList} />
        <BenefitsAndInfo
          info={data.sanityTemplateLogisticsServices.benefitsAndInfo?.info as Info}
          benefits={data.sanityTemplateLogisticsServices.benefitsAndInfo?.benefits as Benefits}
        />
        {has_video && (
          <Video
            code={data.sanityTemplateLogisticsServices.video?.code}
            photo={videoThumbnail}
          />
        )}
        {servicesResumenList.length > 0 && <ServicesResume servicesList={servicesResumenList} />}
        <WeKnowYourProblems
          title={data.sanityTemplateLogisticsServices.weKnowYourProblems?.title}
          problemsList={data.sanityTemplateLogisticsServices.weKnowYourProblems?.PAQsList as Problem[]}
        />
        <div className="wavePAQs_svg">{wavePAQs_svg}</div>
        <WeTalk />
        <SubscribeNewsletter />
      </div>
    </Layout>
  );
};

export default LogisticsServicesTemplate;

export const Head = ({ data }: PageProps<Queries.LogisticsServicesQuery>) => (
  <>
    <SEO
      title={data.sanityTemplateLogisticsServices?.seo?.title}
      robots={data.sanityTemplateLogisticsServices?.seo?.seo_robots}
      description={data.sanityTemplateLogisticsServices?.seo?.description}
      canonical={`logistica/${data.sanityTemplateLogisticsServices?.seo?.canonical}`}
      imageAbsolutePath={data.sanityTemplateLogisticsServices?.seo?.thumbnail?.asset?.url}
    />
    <ServiceMarkupSchema
      titleHero={data.sanityTemplateLogisticsServices?.hero?.title}
      descriptionService={data.sanityTemplateLogisticsServices?.seo?.description}
      absolutePathImageOG={data.sanityTemplateLogisticsServices?.seo?.thumbnail?.asset?.url}
      absolutePathService={`${data.sanityDocumentData?.urlSite}${data.sanityTemplateLogisticsServices?.slug?.current}`}
      problemList={data.sanityTemplateLogisticsServices?.weKnowYourProblems?.PAQsList as Problem[]}
    />
  </>
);

export const query = graphql`
  query LogisticsServices($id: String) {
    sanityDocumentData {
      urlSite
    }
    sanityTemplateLogisticsServices(id: { eq: $id }) {
      seo {
        title
        seo_robots
        description
        canonical
        thumbnail {
          asset {
            url
          }
        }
      }
      slug {
        current
      }
      weKnowYourProblems {
        title
        PAQsList {
          question
          answer: _rawAnswer
        }
      }
      hero {
        title
        subtitle
        _rawText
        photo {
          alt
          image {
            asset {
              gatsbyImageData
              _id
              url
            }
          }
        }
      }
      has_brands
      gallery {
        image {
          asset {
            _id
            gatsbyImageData
          }
        }
      }
      benefitsAndInfo {
        info {
          title
          photo {
            alt
            image {
              asset {
                _id
                gatsbyImageData
              }
            }
          }
          text: _rawText
        }
        benefits {
          title
          benefitsList {
            text: _rawBlock
          }
        }
      }
      video {
        code
        photo: image {
          asset {
            _id
            gatsbyImageData
          }
        }
        alt
      }
    }
    allSanityTemplateLogisticsServices(filter: { id: { ne: $id } }) {
      nodes {
        seo {
          thumbnail {
            asset {
              gatsbyImageData
              _id
            }
          }
        }
        hero {
          title
        }
        slug {
          current
        }
      }
    }
  }
`;
